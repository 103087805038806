<template>
  <div class="tab_one">
    <div class="selector_box">
      <div class="selects">
        <el-select v-model="id_project" placeholder="全部项目" style="margin-right: 14px">
          <el-option v-for="item in projectList" :key="item.id" :label="item.name_project" :value="item.id">
          </el-option>
        </el-select>

        <el-input class="input" placeholder="IMEI号或设备名称进行搜索" v-model="name" prefix-icon="el-icon-search"
          style="margin-right: 14px"></el-input>

        <el-select v-model="run" placeholder="运行状态" style="margin-right: 14px">
          <el-option v-for="item in [
            { id: 1, name: '开机' },
            { id: 0, name: '关机' }
          ]" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>

        <el-select v-model="locked" placeholder="锁定状态" style="margin-right: 14px;">
          <el-option v-for="item in [
            { id: 1, name: '锁定' },
            { id: 0, name: '未锁定' }
          ]" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>

        <el-popover placement="bottom" width="500" trigger="click">
          <el-row class="popover_container">
            <el-col :span="12" class="col">
              <el-select v-model="state" placeholder="设备状态">
                <el-option v-for="item in [
                  { id: 1, name: '在线' },
                  { id: 0, name: '离线' }
                ]" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="12" class="col">
              <el-select v-model="id_building" placeholder="楼栋选择">
                <el-option v-for="item in computedBuildList" :key="item.id" :label="item.name_building" :value="item.id">
                </el-option>
              </el-select>
            </el-col>

            <el-col :span="12" class="col">
              <el-select v-model="floor" placeholder="楼层选择">
                <el-option v-for="item in floorList" :key="item.floor" :label="item.floor + 'F'" :value="item.floor">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-button slot="reference" type="success" plain>更多查询</el-button>
        </el-popover>
      </div>

      <div class="btn_box">
        <el-button type="success" @click="search">查询</el-button>
        <el-button type="info" @click="reset">重置</el-button>
        <el-button type="primary" @click="export2excel">导出</el-button>
      </div>
    </div>

    <!-- 表格 -->
    <div class="table_box">
      <el-table :data="deviceList" style="width: 100%" border :header-cell-style="{
        background: '#fafafb'
      }" size="small" @selection-change="handleSelectionChange" ref="tableRef">
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="" width="40">
          <template v-slot="scope">
            <i v-if="scope.row.locked" class="el-icon-lock" style="font-size: 18px; color:#12203E"></i>
            <i v-else class="el-icon-unlock" style="font-size: 18px; color:#ccc"></i>
          </template>
        </el-table-column>
        <el-table-column prop="name_project" label="项目"></el-table-column>
        <el-table-column prop="addr" label="设备名称"></el-table-column>
        <el-table-column prop="id_device" label="IMEI" width="200"></el-table-column>
        <el-table-column prop="name_building" label="楼栋"></el-table-column>
        <el-table-column label="楼层" width="50">
          <template v-slot="scope">
            <span> {{ scope.row.floor }}F </span>
          </template>
        </el-table-column>
        <el-table-column prop="room_name" label="房间"></el-table-column>
        <el-table-column label="控制规则">
          <template v-slot="scope">
            <div class="table_use_rule">
              <span>{{ scope.row.task && scope.row.task.name }}</span>
              <el-tooltip v-if="
                scope.row.task &&
                scope.row.task.program &&
                scope.row.task.program.name
              " class="item" effect="light" :content="scope.row.task.program.name" placement="top">
                <img src="../../../assets/table_rule.png" alt="" width="14px" height="14px" style="margin-left: 4px" />
              </el-tooltip>
            </div>
            <!-- <span>{{ scope.row.task.program.name }}</span> -->
          </template>
        </el-table-column>
        <el-table-column label="信号值">
          <template v-slot="scope">
            <span>{{ scope.row.state === 1 ? scope.row.rssi : '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备状态">
          <template v-slot="scope">
            <div class="err_box">
              <span>{{ scope.row.state === 1 ? '在线' : '离线' }}</span>
              <el-popover v-show="scope.row.state && scope.row.fault" placement="right" trigger="hover"
                :content="scope.row.fault_desc || '-'">
                <img slot="reference" src="../../../assets/table_err.png" alt="" title="故障" />
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="运行状态">
          <template v-slot="scope">
            <span v-if="scope.row.state !== 0">{{ scope.row.run === 1 ? '开机' : '关机' }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="运行模式">
          <template v-slot="scope">
            <span>{{ getMode(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="室内温度">
          <template v-slot="scope">
            <span class="primary">{{ scope.row.tempIn }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设定温度">
          <template v-slot="scope">
            <span class="offline">{{ scope.row.tempSet }}</span>
          </template>
        </el-table-column>
        <el-table-column label="管理员">
          <template v-slot="scope">
            <span>{{ scope.row.account.real_name || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template v-slot="scope">
            <div class="table_control">
              <img src="../../../assets/table_operation.png" alt="" title="编辑" @click="toDeviceControl(scope.row)" />
              <img src="../../../assets/table_setting.png" alt="" title="配置" @click="showConfigDevice(scope.row)" />
              <img src="../../../assets/table_reset.png" alt="" title="初始化" @click="showResetAlert(scope.row)" />
              <img src="../../../assets/temp_icon.png" alt="" title="温度范围" @click="showTempRange(scope.row)" />
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页功能 -->
      <div class="pagination_box">
        <div class="btn_box">
          <div class="btn btn1" @click="showControlAlert(1, 2)">开机</div>
          <div class="btn btn2" @click="showControlAlert(0, 3)">关机</div>
          <div class="btn btn3" @click="showControlAlert(null, 4)">锁定</div>
          <div class="btn btn4" @click="showControlAlert(null, 5)">解锁</div>
          <el-tooltip content="批量操作可能存在操作失败,请以实际为准" placement="right" effect="light">
            <i class="el-icon-warning-outline" style="color:#e4576a;font-weight: 500;font-size: 24px"></i>
          </el-tooltip>
        </div>
        <el-pagination @size-change="sizeChange" @current-change="currentPageChange" :current-page="page_id + 1"
          :page-sizes="[20, 50, 100, 200]" :page-size="page_num" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 配置设备 -->
    <ConfigDevice ref="configDeviceRef" :buildingList="buildingList" @refresh="getDeviceList" />

    <Alert ref="alertRef" @confirm="alertConfirm" />

    <TempRange ref="tempRangeRef" @confirm="setDeviceTemp" />

    <ExportExcel ref="exportExcelRef" />
  </div>
</template>

<script>
import {
  GetRealDeviceListApi,
  ResetDeviceApi,
  ControlSomeDeviceApi,
  LockSomeDeviceApi,
  UnlockSomeDeviceApi,
  SetDeviceTempApi
} from '@/api'
import { deviceModeFmt } from '@/utils/filter.js'
import project from '@/mixins/project.js'
import building from '@/mixins/building.js'
import floor from '@/mixins/floor.js'
import ConfigDevice from './real_device_cpts/config_device.vue'
import Alert from '@/components/alert/alert.vue'
import TempRange from '@/components/temp_range/temp_range.vue'
import ExportExcel from '@/components/export_excel/export_excel.vue'

export default {
  mixins: [project, building, floor],
  components: { ConfigDevice, Alert, TempRange, ExportExcel },
  data() {
    return {
      // 分页
      page_id: 0,
      page_num: 20,
      total: 0,
      // 项目
      id_project: '',
      // imei或者设别名称查询
      name: '',
      // 运行状态1开机 2关机
      run: '',
      // 锁定状态 1锁定 0未锁定
      locked: '',
      // 设备状态 1在线  0离线
      state: '',
      // 楼栋
      id_building: '',
      // 楼层
      floor: '',
      deviceList: [],
      // 复选框选中的列表
      checkedList: [],
      // 设置温度选中项
      tempInfo: null
    }
  },
  methods: {
    // 分页尺寸变化
    sizeChange(size) {
      this.page_num = size
      this.getDeviceList()
    },

    // 当前页变化
    currentPageChange(page) {
      this.page_id = page - 1
      this.getDeviceList()
    },

    // 点击查询
    search() {
      this.page_id = 0
      this.getDeviceList()
    },

    // 重置
    reset() {
      this.page_id = 0
      this.id_project = ''
      this.name = ''
      this.run = ''
      this.state = ''
      this.locked = ''
      this.id_building = ''
      this.floor = ''
      this.getDeviceList()
    },

    // 获取参数列表
    getParams() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num
      }
      if (this.id_project) {
        params.id_project = this.id_project
      }
      if (this.name.trim()) {
        params.name = this.name.trim()
      }
      if (this.run !== '') {
        params.run = this.run
      }
      if (this.locked !== '') {
        params.locked = this.locked
      }
      if (this.state !== '') {
        params.state = this.state
      }
      if (this.id_building) {
        params.id_building = this.id_building
      }
      if (this.floor) {
        params.floor = this.floor
      }
      return params
    },

    // 获取设备列表
    async getDeviceList() {
      const params = this.getParams()
      const { ret, data, msg } = await GetRealDeviceListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.total = data.cnt_all
      this.deviceList = data.data
    },

    // 计算运行模式
    getMode(row) {
      if (row.state !== 1 || row.run !== 1) {
        return '--'
      } else {
        return deviceModeFmt(row.mode)
      }
    },

    // 显示配置弹窗
    showConfigDevice(row) {
      this.$refs.configDeviceRef.config(row)
    },

    // 显示重置弹窗
    showResetAlert(row) {
      this.$refs.alertRef.alert(
        {
          title: '初始化提醒',
          msg: [
            '您确定要初始化该设备吗? 初始化后',
            '该设备会变成待配置状态, IMEI会变成未配对状态'
          ]
        },
        row,
        1
      )
    },

    // alert 事件分发
    alertConfirm({ type, info }) {
      if (type === 1) {
        this.deviceReset(info)
      } else if (type === 2 || type === 3) {
        this.controlSome(info)
      } else if (type === 4) {
        this.lockSome()
      } else if (type === 5) {
        this.unLockSome()
      }
    },

    // 确定重置
    async deviceReset(info) {
      const params = {
        id_device: info.id_device
      }
      const { ret, data, msg } = await ResetDeviceApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('初始化成功')
      this.getDeviceList()
    },

    // 去设备控制界面
    toDeviceControl(row) {
      this.$router.push('/device/device_control?id=' + row.id_device)
    },

    // 复选框发生变化
    handleSelectionChange(selection) {
      this.checkedList = selection
    },

    // 显示批量控制设备弹窗
    showControlAlert(info, type) {
      if (!this.checkedList.length) {
        return this.$message.warning('暂无选中任何设备!')
      }

      if (type === 2) {
        this.$refs.alertRef.alert(
          {
            title: '批量开机提醒',
            msg: ['您确定要批量开启设备吗?(已开启设备不会受到影响!)']
          },
          1,
          2
        )
      } else if (type === 3) {
        this.$refs.alertRef.alert(
          {
            title: '批量关机提醒',
            msg: ['您确定要批量关闭设备吗?(已关闭设备不会受到影响!)']
          },
          0,
          3
        )
      } else if (type === 4) {
        this.$refs.alertRef.alert(
          {
            title: '批量锁定提醒',
            msg: ['您确定要批量锁定设备吗?(已锁定设备不会受到影响!)']
          },
          null,
          4
        )
      } else {
        this.$refs.alertRef.alert(
          {
            title: '批量解锁提醒',
            msg: ['您确定要批量解锁设备吗?(已解锁设备不会受到影响!)']
          },
          null,
          5
        )
      }
    },

    // 批量开机
    async controlSome(run) {
      const ids = []
      this.checkedList.map(i => {
        ids.push(i.id_device)
      })
      const params = {
        device_array: ids,
        run
      }
      const { ret, data, msg } = await ControlSomeDeviceApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success(`批量${run ? '开机' : '关机'}指令下发成功`)
      this.$refs.tableRef.clearSelection()
    },

    // 批量锁定
    async lockSome() {
      const ids = []
      this.checkedList.map(i => {
        ids.push(i.id_device)
      })
      const { ret, data, msg } = await LockSomeDeviceApi(ids)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('指令下发成功')
      this.$refs.tableRef.clearSelection()
    },

    // 批量解锁
    async unLockSome() {
      const ids = []
      this.checkedList.map(i => {
        ids.push(i.id_device)
      })
      const { ret, data, msg } = await UnlockSomeDeviceApi(ids)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('指令下发成功')
      this.$refs.tableRef.clearSelection()
    },

    // 显示温度区域
    showTempRange(row) {
      this.tempInfo = row
      this.$refs.tempRangeRef.open(1, row)
    },

    // 设置设备温度
    async setDeviceTemp(args) {
      const params = {
        ...args,
        id_device: this.tempInfo.id_device
      }
      const { ret, data, msg } = await SetDeviceTempApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('设置成功')
      this.getDeviceList()
    },

    // 导出excel
    export2excel() {
      const params = this.getParams()
      this.$refs.exportExcelRef.export({
        filename: '设备列表',
        func: GetRealDeviceListApi,
        params,
        datapath: ['data'],
        countpath: ['cnt_all'],
        kw: {
          name_project: '项目',
          addr: '设备名称',
          id_device: 'IMEI',
          name_building: '楼栋',
          floor: '楼层',
          room_name: '房间',
          rssi: '信号值',
          state: '设备状态',
          run: '运行状态',
          mode: '运行模式',
          tempIn: '室内温度',
          tempSet: '设定温度',
          account: '管理员'
        },
        fmt: {
          floor(v) {
            return v + 'F'
          },
          state(v) {
            return v ? '在线' : '离线'
          },
          run(v, row) {
            if (row.state !== 0) {
              return v === 1 ? '开机' : '关机'
            } else {
              return '--'
            }
          },
          mode: this.getMode,
          account(v) {
            return v.real_name || '暂无'
          }
        }
      })
    }
  },

  created() {
    this.getProjectList()
    this.getBuildingList()
    this.getFloorList()
    this.getDeviceList()
  },

  computed: {
    computedBuildList() {
      if (this.id_project) {
        return this.buildingList.filter(i => {
          return i.id_project === this.id_project
        })
      } else {
        return this.buildingList
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab_one {
  padding: 0 22px;
}

.selector_box {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .btn_box {
    flex: 0 0 240px;
    text-align: right;
  }
}

.table_box {
  .table_use_rule {
    display: flex;
    align-items: center;
  }

  .err_box {
    display: flex;
    align-items: center;

    img {
      width: 12px;
      height: 12px;
      margin-left: 6px;
    }
  }
}

.pagination_box {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn_box {
    display: flex;
    align-items: center;

    .btn {
      width: 100px;
      height: 36px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      margin-right: 12px;
      cursor: pointer;

      &.btn1 {
        background: #e5ffef;
        border: 1px solid #50e399;
        color: #50e399;
        transition: all 0.3s;

        &:hover {
          background-color: rgba(229, 255, 239, 0.5);
        }
      }

      &.btn2 {
        background: #e7f0ff;
        border: 1px solid #1670ff;
        color: #1670ff;
        transition: all 0.3s;

        &:hover {
          background-color: rgba(231, 240, 255, 0.5);
        }
      }

      &.btn3 {
        background: #f2f2fe;
        border: 1px solid #7d7df9;
        color: #7d7df9;
        transition: all 0.3s;

        &:hover {
          background-color: rgba(242, 242, 254, 0.5);
        }
      }

      &.btn4 {
        color: #ced7e1;
        background: #feffff;
        border: 1px solid #ced7e1;
        transition: all 0.3s;

        &:hover {
          background-color: rgba(254, 255, 255, 0.5);
        }
      }
    }
  }
}
</style>
