<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="配对"
    width="600px"
    @closed="clearForm"
  >
    <div class="form_box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="formRef"
        label-width="60px"
      >
        <el-form-item label="IMEI" prop="id_device">
          <el-select
            v-model="ruleForm.id_device"
            filterable
            remote
            :remote-method="getImeiList"
            placeholder="请选择IMEI"
            :loading="loading"
            class="w100"
          >
            <el-option
              v-for="item in options"
              :key="item.id_device"
              :label="item.id_device"
              :value="item.id_device"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { GetImeiListApi, PairDeviceApi } from '@/api'
import { check_imei } from '@/utils/validator.js'

export default {
  data() {
    return {
      // 当前行
      row: '',
      // 是否显示弹窗
      dialogVisible: false,
      // 编辑还是修改
      isEdit: false,
      // 表单
      ruleForm: {
        id_device: ''
      },
      // 规则
      rules: {
        id_device: check_imei
      },
      // IMEI备选
      options: [],
      // 是否在加载中
      loading: false
    }
  },
  methods: {
    // 添加账号
    open(row) {
      this.row = row
      this.dialogVisible = true
      this.getImeiList()
    },

    // 获取imei列表
    async getImeiList(kw = '') {
      const params = {
        id_operator: this.row.id_operator,
        page_id: 0,
        page_num: 20
      }
      if (kw.trim()) {
        params.id_device = kw.trim()
      }
      const { ret, data, msg } = await GetImeiListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      const options = []
      data.data.map(i => {
        options.push({
          id_device: i.id_device
        })
      })
      this.options = options
    },

    // 点击了确定
    confirm() {
      this.$refs.formRef.validate(async _ => {
        if (!_) return
        const params = {
          id: this.row.id,
          id_device: this.ruleForm.id_device
        }
        const { ret, data, msg } = await PairDeviceApi(params)
        if (ret !== 0) {
          return this.$message.error(msg)
        }
        this.$message.success('配对成功')
        this.dialogVisible = false
        this.$emit('refresh')
      })
    },

    // 清除数据
    clearForm() {
      this.options = []
      this.ruleForm.id_device = ''
      try {
        this.$refs.formRef.resetFields()
      } catch (e) {}
    }
  }
}
</script>

<style lang="scss" scoped>
.form_box {
  padding: 64px 60px 32px;
}
</style>
