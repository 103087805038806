<template>
  <div class="tab_one">
    <div class="selector">
      <el-select
        v-model="id_operator"
        placeholder="全部运营商"
        style="margin-right: 14px"
        v-supAdmin
      >
        <el-option
          v-for="item in operatorList"
          :key="item.id_operator"
          :label="item.name_operator"
          :value="item.id_operator"
        >
        </el-option>
      </el-select>

      <el-select
        v-model="id_project"
        placeholder="全部项目"
        style="margin-right: 14px"
      >
        <el-option
          v-for="item in computedProject"
          :key="item.id"
          :label="item.name_project"
          :value="item.id"
        >
        </el-option>
      </el-select>

      <el-input
        class="input"
        placeholder="设备名称进行搜索"
        v-model="addr"
        prefix-icon="el-icon-search"
      ></el-input>

      <el-button type="success" icon="el-icon-search" @click="search"
        >查询</el-button
      >
      <el-button type="info" icon="el-icon-refresh-left" @click="reset"
        >重置</el-button
      >
    </div>

    <div class="add_box">
      <el-button icon="el-icon-plus" type="primary" @click="addDevice"
        >添加设备</el-button
      >
    </div>

    <!-- 表格 -->
    <div class="table_box">
      <el-table
        :data="deviceList"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#fafafb'
        }"
        size="small"
      >
        <el-table-column
          v-if="level === 100"
          label="运营商"
          prop="name_operator"
        ></el-table-column>
        <el-table-column label="项目" prop="name_project"></el-table-column>
        <el-table-column label="房间" prop="room_name"></el-table-column>
        <el-table-column label="设备名称">
          <template v-slot="scope">
            <span class="edit">{{ scope.row.addr }}</span>
          </template>
        </el-table-column>
        <el-table-column label="楼栋" prop="name_building"> </el-table-column>
        <el-table-column label="楼层">
          <template v-slot="scope">
            <span>{{ scope.row.floor }}F</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="time_create"></el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <div class="table_control">
              <img
                src="../../../assets/table_edit.png"
                alt=""
                title="编辑"
                @click="editDevice(scope.row)"
              />
              <img
                src="../../../assets/table_delete.png"
                alt=""
                title="删除"
                @click="showDelAlert(scope.row)"
              />
              <img
                src="../../../assets/table_pair.png"
                alt=""
                title="配对"
                @click="showPairDialog(scope.row)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页功能 -->
      <div class="pages">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentPageChange"
          :current-page="page_id + 1"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="page_num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 添加修改设备弹窗 -->
    <ChangeDevice
      ref="changeDeviceRef"
      :operatorList="operatorList"
      :projectList="projectList"
      @refresh="getWaitConfigList"
    />

    <!-- 配对设备 -->
    <PairDevice ref="pairDeviceRef" @refresh="getWaitConfigList" />

    <!-- 弹窗 -->
    <Alert ref="alertRef" @confirm="delDevice" />
  </div>
</template>

<script>
import { GetWaitConfigListApi, DelDeviceApi } from '@/api'
import ChangeDevice from './wait_config_cpts/change_device.vue'
import PairDevice from './wait_config_cpts/pair_device.vue'
import Alert from '@/components/alert/alert.vue'

export default {
  props: ['operatorList', 'projectList'],
  components: { ChangeDevice, Alert, PairDevice },
  data() {
    return {
      // 权限级别
      level: '',
      // 运营商
      id_operator: '',
      // 项目
      id_project: '',
      // 设备名称
      addr: '',
      // 设备列表
      deviceList: [],
      // 分页
      page_id: 0,
      page_num: 20,
      total: 0
    }
  },
  methods: {
    // 分页尺寸变化
    sizeChange(size) {
      this.page_num = size
      this.getWaitConfigList()
    },
    // 当前页变化
    currentPageChange(page) {
      this.page_id = page - 1
      this.getWaitConfigList()
    },

    // 查询
    search() {
      this.page_id = 0
      this.getWaitConfigList()
    },

    // 重置
    reset() {
      this.id_operator = ''
      this.id_project = ''
      this.addr = ''
      this.page_id = 0
      this.getWaitConfigList()
    },

    // 获取未配置设备列表
    async getWaitConfigList() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num
      }
      if (this.id_operator) {
        params.id_operator = this.id_operator
      }
      if (this.id_project) {
        params.id_project = this.id_project
      }
      if (this.addr.trim()) {
        params.addr = this.addr.trim()
      }
      const { ret, data, msg } = await GetWaitConfigListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.total = data.cnt_all
      this.deviceList = data.data
    },

    // 点击添加设备
    addDevice() {
      this.$refs.changeDeviceRef.add()
    },

    // 显示删除的alert
    showDelAlert(row) {
      this.$refs.alertRef.alert(
        {
          title: '删除提醒',
          msg: '您确定要删除该设备吗? 此操作不可恢复!'
        },
        row,
        1
      )
    },

    // 删除设备
    async delDevice({ info, type }) {
      const params = {
        id: info.id
      }
      const { ret, data, msg } = await DelDeviceApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('删除成功')
      this.getWaitConfigList()
    },

    // 编辑设备
    editDevice(row) {
      this.$refs.changeDeviceRef.edit(row)
    },

    // 显示配对弹窗
    showPairDialog(row) {
      this.$refs.pairDeviceRef.open(row)
    }
  },
  created() {
    this.level = this.$store.getters.level
    this.getWaitConfigList()
  },
  computed: {
    // 过滤项目
    computedProject() {
      if (this.level === 100) {
        if (this.id_operator) {
          return this.projectList.filter(i => {
            return i.id_operator === this.id_operator
          })
        } else {
          return this.projectList
        }
      } else {
        return this.projectList
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab_one {
  padding: 0 22px;
}

.selector {
  margin: 20px 0;

  .input {
    margin-right: 20px;
  }
}

.table_box {
  margin-top: 20px;
}
</style>
