<template>
  <div class="device_list">
    <div class="card">
      <!-- 空调列表 待配置 -->
      <div class="nav_box">
        <div @click="nav = 1" :class="nav === 1 ? 'current' : 'nav'">
          空调列表
        </div>
        <div @click="nav = 2" :class="nav === 2 ? 'current' : 'nav'">
          待配置设备
        </div>
      </div>
      <div class="content_box">
        <keep-alive>
          <component
            v-bind:is="getCompoent"
            :operatorList="operatorList"
            :projectList="projectList"
          ></component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import RealDevice from './real_device.vue'
import WaitConfig from './wait_config.vue'
import operator from '@/mixins/operator.js'
import project from '@/mixins/project.js'

export default {
  name: 'device_list',
  components: { RealDevice, WaitConfig },
  mixins: [operator, project],
  data() {
    return {
      // 权限级别
      level: '',
      // 1 设备列表 2 待配置
      nav: 1
    }
  },
  created() {
    this.level = this.$store.getters.level
    if (this.level === 100) {
      this.getOperatorList()
    }
    this.getProjectList()
  },
  computed: {
    getCompoent() {
      if (this.nav === 1) {
        return RealDevice
      } else {
        return WaitConfig
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.device_list {
  padding: 0 22px;
}

.card {
  margin-top: 24px;
  min-height: calc(100vh - 160px);

  .nav_box {
    height: 48px;
    border-bottom: 1px solid #ebedf2;
    display: flex;
    overflow: hidden;

    .nav {
      position: relative;
      height: 100%;
      line-height: 48px;
      margin-left: 40px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #12203e;
      cursor: pointer;
    }

    .current {
      @extend .nav;

      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 50%;
        width: 88px;
        height: 3px;
        background: linear-gradient(-75deg, #50e379, #50e398);
        box-shadow: 0px 1px 2px 0px rgba(80, 227, 146, 0.35);
        transform: translateX(-50%);
      }
    }
  }
}
</style>
