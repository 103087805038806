<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :title="isEdit ? '修改设备' : '添加设备'"
    width="800px"
    :closeOnClickModal="false"
    @closed="resetForm"
  >
    <div class="form_box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="formRef"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <!-- 这个地方用双表单是因为修改的话, 只能改单台设备, 所以名称可以随意 -->
            <el-form-item label="设备名称" :prop="isEdit ? 'addr2' : 'addr'">
              <el-input v-if="isEdit" v-model="ruleForm.addr2"></el-input>
              <el-input v-else v-model="ruleForm.addr"></el-input>
            </el-form-item>
          </el-col>

          <template v-if="!isEdit">
            <el-col :span="12">
              <el-form-item label="新增数量" prop="num">
                <el-input v-model="ruleForm.num"></el-input>
              </el-form-item>
            </el-col>
          </template>

          <template v-if="!isEdit && level === 100">
            <el-col :span="12">
              <el-form-item label="运营商" prop="id_operator">
                <el-select
                  v-model="ruleForm.id_operator"
                  placeholder="请选择运营商"
                  class="w100"
                  @change="selectOperator"
                >
                  <el-option
                    :label="item.name_operator"
                    :value="item.id_operator"
                    v-for="item in operatorList"
                    :key="item.id_operator"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </template>

          <el-col :span="12">
            <el-form-item label="项目" prop="id_project">
              <el-select
                v-model="ruleForm.id_project"
                placeholder="请选择项目"
                class="w100"
                :disabled="level === 100 && !ruleForm.id_operator && isEdit"
                @change="getBuild"
              >
                <el-option
                  v-for="item in computedProject"
                  :key="item.id"
                  :label="item.name_project"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="楼栋" prop="id_building">
              <el-select
                v-model="ruleForm.id_building"
                placeholder="请选择楼栋"
                class="w100"
                :disabled="!ruleForm.id_project"
              >
                <el-option
                  v-for="item in buildingList"
                  :key="item.id"
                  :label="item.name_building"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="楼层" prop="floor">
              <el-select
                v-model="ruleForm.floor"
                placeholder="请选择楼层"
                class="w100"
              >
                <el-option
                  v-for="item in floor"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="房间名" prop="room_name">
              <el-select
                v-model="ruleForm.room_name"
                filterable
                allow-create
                default-first-option
                placeholder="请输入/选择房间"
                remote
                :remote-method="getRoomList"
                :loading="loading"
                :disabled="!ruleForm.id_building"
                class="w100"
                @focus="getRoomList('')"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  check_some_device_name,
  check_device_name,
  check_num,
  check_operator,
  check_project,
  check_build,
  check_floor,
  check_name_room
} from '@/utils/validator.js'
import building from '@/mixins/building.js'
import { AddWaitConfigDeviceApi, GetRoomListApi, EditDeviceApi } from '@/api'

const floor = Array.from({ length: 32 }, (_, i) => {
  return {
    id: i + 1,
    name: i + 1 + ' 楼'
  }
})

export default {
  props: ['operatorList', 'projectList'],
  mixins: [building],
  data() {
    return {
      // 权限级别
      level: '',
      // 是否显示弹窗
      dialogVisible: false,
      // 编辑还是修改
      isEdit: false,
      // 表单
      ruleForm: {
        id: '',
        // 添加用
        addr: '',
        // 修改用
        addr2: '',
        num: '',
        id_operator: '',
        id_project: '',
        id_building: '',
        floor: '',
        room_name: ''
      },
      // 规则
      rules: {
        addr: check_some_device_name,
        addr2: check_device_name,
        num: check_num,
        id_operator: check_operator,
        id_project: check_project,
        id_building: check_build,
        floor: check_floor,
        room_name: check_name_room
      },
      floor,
      // 是否正在从远端拉取数据
      loading: false,
      // 可选择的房间名称
      options: []
    }
  },
  methods: {
    // 添加账号
    add() {
      this.level = this.$store.getters.level
      this.isEdit = false
      this.dialogVisible = true
    },

    // 编辑账号
    edit(row) {
      this.level = this.$store.getters.level
      this.isEdit = true
      this.dialogVisible = true
      this.getBuildingList({
        id_project: row.id_project
      })
      this.$nextTick(() => {
        this.ruleForm.addr2 = row.addr
        this.ruleForm.id_operator = row.id_operator
        this.ruleForm.id_project = row.id_project
        this.ruleForm.id_building = row.id_building
        this.ruleForm.floor = row.floor
        this.ruleForm.room_name = row.room_name
        this.ruleForm.id = row.id
      })
    },

    // 选择了运营商
    selectOperator() {
      this.ruleForm.id_project = ''
      this.ruleForm.id_building = ''
    },

    // 获取楼栋
    async getBuild() {
      this.ruleForm.id_building = ''
      const params = {
        id_project: this.ruleForm.id_project
      }
      this.getBuildingList(params)
    },

    // 过滤房间
    async getRoomList(kw) {
      this.loading = true
      const params = {
        id_building: this.ruleForm.id_building
      }
      if (kw.trim()) {
        params.room_name = kw.trim()
      }
      const { ret, data, msg } = await GetRoomListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      const options = []
      data.map((i, index) => {
        options.push({
          label: i.room_name,
          value: index
        })
      })
      this.options = options
      this.loading = false
    },

    // 重置表单
    resetForm() {
      try {
        this.$refs.formRef.resetFields()
      } catch (e) {}
    },

    // 点击确定
    confirm() {
      this.$refs.formRef.validate(_ => {
        if (!_) return
        if (this.isEdit) {
          this.confirmEdit()
        } else {
          this.confirmAdd()
        }
      })
    },

    // 确定添加
    async confirmAdd() {
      console.log(this.ruleForm)
      const params = {
        addr: this.ruleForm.addr.trim(),
        id_project: this.ruleForm.id_project,
        id_building: this.ruleForm.id_building,
        floor: this.ruleForm.floor,
        num: this.ruleForm.num / 1,
        room_name: this.ruleForm.room_name.trim()
      }
      const { ret, msg } = await AddWaitConfigDeviceApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('添加成功')
      this.dialogVisible = false
      this.$emit('refresh')
    },

    // 确定修改
    async confirmEdit() {
      const params = {
        id: this.ruleForm.id,
        id_project: this.ruleForm.id_project,
        addr: this.ruleForm.addr2,
        id_building: this.ruleForm.id_building,
        floor: this.ruleForm.floor,
        room_name: this.ruleForm.room_name
      }
      const { ret, data, msg } = await EditDeviceApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('修改成功')
      this.dialogVisible = false
      this.$emit('refresh')
    }
  },

  computed: {
    computedProject() {
      if (this.level === 100) {
        if (this.ruleForm.id_operator) {
          return this.projectList.filter(i => {
            return i.id_operator === this.ruleForm.id_operator
          })
        } else {
          return this.projectList
        }
      } else {
        return this.projectList
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form_box {
  padding: 64px 60px 32px;
}
</style>
